.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
a {
  color: #4A90E2;
  text-decoration: none;
}
body {
  color: #333;
  font-family: 'Roboto', sans-serif;
  height: 100%;
  -webkit-font-smoothing: antialiased;
}
input {
  background: #F1F1F1;
  border: none;
  border-bottom: 1px solid #EEE;
  display: block;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  height: 33px;
  margin-bottom: 5px;
  padding-left: 10px;
  transition: all 0.2s;
  width: 100%;
}
input:focus {
  background: #FFF;
  border-bottom: 1px solid #333;
  outline: none;
}
input:hover {
  background: #FFF;
  border-bottom: 1px solid #AAA;
}
button,
input[type=submit] {
  /* background: #777; */
  background-image: linear-gradient(to right, #003C88, #009ED0);
  border: none;
  color: #FFF;
  cursor: pointer;
  display: block;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  height: 40px;
  margin-bottom: 5px;
  margin-top: 10px;
  padding-left: 0;
  transition: all 0.2s;
  width: 100%;
}
button:hover,
input[type=submit]:hover {
  /* background: #555; */
  background-image: linear-gradient(to right #003C88, #009ED0);
}
form {
  margin-bottom: 10px;
  padding: 0 10px;
}
h1 {
  font-size: 18px;
  margin-bottom: 10px;
}
p {
  margin-bottom: 5px;
}
p span {
  font-weight: 700;
}
.error {
  color: #D0011B;
  font-size: 14px;
  padding: 5px;
  text-align: center;
}
.success {
  color: #7ED321;
  font-size: 14px;
  padding: 5px;
  text-align: center;
}

.App{
  padding: 0 30px;
}
.spinner {
  margin: 10px;
  justify-content: center;
    display: flex;
}
.three-bounce > div {
  background: #CCC;
}
.Home-section {
  margin-top: 10px;
  text-align: center;
}
.appbar {
  padding: 20px 10%;
  display: flex;
  justify-content: center;
}
.children {
  
  margin:100px  auto;
  max-width: 400px;
}
.text-center {
  text-align: center;
}

.space-y {
  height: 20px;
}
.space-x {
  width: 20px;
}

.logo{
  height: 50px;
}
.flex {
  display: flex;
  align-items: center;
}
.large-text {
  font-size: 32px;
}